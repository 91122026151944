import { createWebHistory, createWebHashHistory, createRouter } from "vue-router";

const Home = () => import("@/components/Home");
const RisultatoRicerca = () => import("@/components/Risultato-ricerca");
const Awards = () => import("@/components/Awards");
const News = () => import("@/components/News");
//const Opportunita = () => import("@/components/Opportunita");
const Publicazioni = () => import("@/components/Publicazioni");
const PublicazioniRicerca = () => import("@/components/Publicazioni-ricerca");
const Newsletters = () => import("@/components/Newsletters");

const Platforms = () => import("@/components/Platforms");

const Board = () => import("@/components/Board");
const Presidenti = () => import("@/components/Presidenti");
const Committee = () => import("@/components/Committee");
const CommitteeFunctionalunit = () => import("@/components/Committee-functionalunit");
const CommitteeTaskforce = () => import("@/components/Committee-taskforce");

const Eventi = () => import("@/components/Eventi");

const GeneralMeetings= () => import("@/components/General-meetings");

const NationalGuidelines = () => import("@/components/Nationals-guidelines");
const NationalGuidelinesTopicdettaglio = () => import("@/components/Nationals-guidelines-topic");

const Loadpage = () => import("@/components/Loadpage");
const DownloadFile = () => import("@/components/Teras/Download");

var mode = (process.env.NODE_ENV != "development") ? createWebHistory("/site/") : createWebHashHistory("/site/")

export const createVueRouter = (app) => {
   const router = createRouter({
      history: mode,

      scrollBehavior (to, from, savedPosition) {
         document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      },

      routes: [
         { path: "/", name: "home", meta: { requiresEvent: false, requiresAuth: false, title: "EFLM :: European Federation of Clinical Chemistry and Laboratory Medicine" }, components: { main: Home } },
         { path: "/search/:ricerca", name: "search", meta: { requiresEvent: false, requiresAuth: false, title: "Search result" }, components: { main: RisultatoRicerca } },
         { path: "/eflm-platforms", name: "eflm-platforms", meta: { requiresEvent: false, requiresAuth: false, title: "EFLM Platforms" }, components: { main: Platforms } },

         { path: "/eflm-awards", name: "eflm-awards", meta: { requiresEvent: false, requiresAuth: false, title: "EFLM Awards" }, components: { main: Awards } },
         { path: "/eflm-news", name: "eflm-news", meta: { requiresEvent: false, requiresAuth: false, title: "EFLM News" }, components: { main: News } },
         { path: "/eflm-publications", name: "eflm-publications", meta: { requiresEvent: false, requiresAuth: false, title: "EFLM Pubblications" }, components: { main: Publicazioni } },
         { path: "/pubsearch/:termine", name: "eflm-publications-research", meta: { requiresEvent: false, requiresAuth: false, title: "EFLM Pubblications search results" }, components: { main: PublicazioniRicerca } },
         //{ path: "/eflm-opportunities", name: "eflm-opportunities", meta: { requiresEvent: false, requiresAuth: false, title: "EFLM Opportunities" }, components: { main: Opportunita } },
         { path: "/newsletters", name: "newsletters", meta: { requiresEvent: false, requiresAuth: false, title: "Newsletters" }, components: { main: Newsletters } },
         { path: "/who-we-are/executive-board", name: "executive-board", meta: { requiresEvent: false, requiresAuth: false, title: "Executive Board" }, components: { main: Board } },
         { path: "/who-we-are/wall-of-eflm-presidents", name: "wall-of-eflm-presidents", meta: { requiresEvent: false, requiresAuth: false, title: "The wall of EFLM Presidents" }, components: { main: Presidenti } },
         { path: "/who-we-are/committee/:committee", name: "committee", meta: { requiresEvent: false, requiresAuth: false, title: "Committee #NOME#" }, components: { main: Committee } },
         { path: "/who-we-are/committee/:committee/fu/:functionalunit", name: "committee-functionalunit", meta: { requiresEvent: false, requiresAuth: false, title: "Functional Unit #NOME#" }, components: { main: CommitteeFunctionalunit } },
         { path: "/who-we-are/committee/:committee/tf/:taskforce", name: "committee-taskforce", meta: { requiresEvent: false, requiresAuth: false, title: "Task Force #NOME#" }, components: { main: CommitteeTaskforce } },

         { path: "/calendar/events", name: "events", meta: { requiresEvent: false, requiresAuth: false, title: "Events" }, components: { main: Eventi } },

         { path: "/general-meetings", name: "general-meetings", meta: { requiresEvent: false, requiresAuth: false, title: "General Meetings" }, components: { main: GeneralMeetings } },
         
         { path: "/national-guidelines", name: "national-guidelines", meta: { requiresEvent: false, requiresAuth: false, title: "National Guidelines" }, components: { main: NationalGuidelines } },
         { path: "/national-guidelines/:topic", name: "national-guidelines-topic", meta: { requiresEvent: false, requiresAuth: false, title: "#NOME# - National Guidelines" }, components: { main: NationalGuidelinesTopicdettaglio } },

         { path: "/dwn/:file", name: "download-file", meta: { requiresEvent: false, requiresAuth: false, title: "" }, components: { main: DownloadFile } },
         
         { path: "/:filename", name: "page",  meta: { inside:"page", nav: true }, components:{main: Loadpage },props: {main: true} }, 
         { path: "/:area/:filename", name: "page-area",  meta: { inside:"page-area", nav: true }, components:{main: Loadpage },props: {main: true} }, 
         { path: "/:area/:subarea/:filename", name: "page-subarea",  meta: { inside:"page-subarea", nav: true }, components:{main: Loadpage },props: {main: true} }, 
         { path: "/:area/:subarea/:subsubarea/:filename", name: "page-subsubarea",  meta: { inside:"page-subsubarea", nav: true }, components:{main: Loadpage },props: {main: true} }, 

         { path: "/:catchAll(.*)", redirect: "/" } 
      ]


   })

   router.beforeEach(async (to, from, next) => {
      var teras = app.config.globalProperties.$teras
      let error = false
      teras.app.drawer = false
      //to.query contiene le variabili passate in modo standard es. ?variabile=valore
      //to.params contiene le variabili passate in vuejs passate in base a routes[]
      console.log("\nbeforeEach \ncheckFirst: "+router.checkFirst+"\nfrom: "+from.path+"\nto: "+to.path+"\n\n")
      teras.router.from = from.path
      teras.router.to = to.path

      if (!teras.check.first){
         console.log("SONO RICHIESTE LE OPERAZIONI DI PRIMO AVVIO")
         teras.event.emit("checkFirst", from, to)
         error= true;
      }
      
      console.log("AUTH "+teras.app.auth +" AND error "+error)
      if (!error && to.matched.some(record => record.meta.requiresAuth) && !teras.check.auth){
         console.log("È RICHIESTO UTENTE E NON È ANCORA VERIFICATO")
         teras.event.emit("checkAuth", from, to)
         error = true
      }
    
      if(error){
          next(false)
      }else{
         console.log("beforeEach next")
         window.scrollTo(0,0)
         teras.app.ready = true
         if(to.name=="home"){
            document.title= to.meta.title
         } else{
            document.title= to.meta.title+" | "+teras.defaultPageTitle
         }
         next()
         teras.event.emit("routerChange", to)
      }
   })

   return router
}
